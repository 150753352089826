import dynamic from "next/dynamic";
import { Section } from "..";
import {
  CardAndMiniCardGridWrapper,
  CardGridWrapper,
  MiniCardGridWrapper,
} from "./styles";

const CardItem = dynamic(() => import("../../elements/card-item/card-item"));
const MiniCardItem = dynamic(() =>
  import("../../elements/mini-card-item/mini-card-item")
);

export const CardAndMiniCardGrid = ({
  id,
  title,
  sectionSubtitle,
  hideLineSeparation,
  miniCardItems,
  cardItems,
  displaySetting
}) => {
  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <CardAndMiniCardGridWrapper>
        <CardGridWrapper>
          {cardItems.map((card) => (
            <CardItem key={card.id} {...card} />
          ))}
        </CardGridWrapper>
        <MiniCardGridWrapper>
          {miniCardItems.map((miniCard) => (
            <MiniCardItem key={miniCard.id} {...miniCard} />
          ))}
        </MiniCardGridWrapper>
      </CardAndMiniCardGridWrapper>
    </Section>
  );
};

export default CardAndMiniCardGrid;
