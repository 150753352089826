import styled from "styled-components";

export const CardAndMiniCardGridWrapper = styled.div`
  display: grid;
  gap: var(--spacingM);
  > ul {
  margin: 0px;
  padding: 0px;
  }
`;

export const CardGridWrapper = styled.ul`
  display: grid;
  gap: var(--spacingM);
  grid-template-columns: 1fr;
  grid-template-rows: auto;  
  
  @media screen and (min-width: 1128px) {
    gap: var(--spacingL);
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const MiniCardGridWrapper = styled.ul`
  display: grid;
  gap: var(--spacingS);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  @media screen and (min-width: 1128px) {
    gap: var(--spacingM);
    grid-template-columns: repeat(6, 1fr);
  }
`;

